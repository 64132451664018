import VueBaseController from './vue_base'

export class LinksController extends VueBaseController { // eslint-disable-line import/prefer-default-export
  static get COMPONENT() { return () => import('@/vue/pages/landers/new_link_rec.vue') }

  static values = {
    apiBase: String
  }

  connect() {
    super.connect({
      apiBase: this.apiBaseValue
    })
  }
}

export class Links2Controller extends VueBaseController { // eslint-disable-line import/prefer-default-export
  static get COMPONENT() { return () => import('@/vue/pages/landers/new_link_rec_variant.vue') }

  static values = {
    apiBase: String
  }

  connect() {
    super.connect({
      apiBase: this.apiBaseValue
    })
  }
}

export class LinkController extends VueBaseController { // eslint-disable-line import/prefer-default-export
  static get COMPONENT() { return () => import('@/vue/pages/landers/link_rec.vue') }

  static values = {
    linkRecommendationId: String,
    silenceAnalytics: Boolean,
    advancedReportRequested: Boolean,
  }

  connect() {
    super.connect({
      linkRecommendationId: this.linkRecommendationIdValue,
      silenceAnalytics: this.silenceAnalyticsValue,
      advancedReportRequested: this.advancedReportRequestedValue
    })
  }
}
