import VueBaseController from '../../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/link_building/platforms/index.vue') }
}

export class NewController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/link_building/platforms/new.vue') }
}

export class EditController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/link_building/platforms/edit.vue') }

  static values = {
    platformId: String
  }

  connect() {
    super.connect({
      platformId: this.platformIdValue
    })
  }
}
